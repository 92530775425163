import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Sidebar/Navbar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { updateUserProfile } from "../services/plan-service";
import './LinkedHelperAcc.css';

function LinkedHelperAcc() {
  const [userInfo, setUserInfo] = useState({
    email: '',
    userName: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [passwordFormVisible, setPasswordFormVisible] = useState(false);

  useEffect(() => {
    const storedUserInfo = JSON.parse(localStorage.getItem('user'));
    if (storedUserInfo) {
      setUserInfo(storedUserInfo);
    }
  }, []);

  const handleSaveInfo = async (e) => {
    e.preventDefault();

    try {
      await updateUserProfile({
        user_name: userInfo.userName,
        password: userInfo.newPassword,
        oldPassword: userInfo.oldPassword,
        confirmPassword: userInfo.confirmPassword,
      });

      const storedUserInfo = JSON.parse(localStorage.getItem('user'));
      if (storedUserInfo) {
        storedUserInfo.userName = userInfo.userName;
        localStorage.setItem('user', JSON.stringify(storedUserInfo));
      }

      setUserInfo(prevUserInfo => ({
        ...prevUserInfo,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      }));

      setPasswordFormVisible(false);
      toast.success('Information updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      if (error.response && error.response.status === 401) {
        toast.error('Enter correct old password');
      } else {
        toast.error('New password and confirm password not matched.');
      }
    }
  };


  const handleCancelInfo = () => {
    setPasswordFormVisible(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      [name]: value,
    }));
  };

  const handleChangePasswordClick = () => {
    setPasswordFormVisible(true);
  };

  return (
    <div>
      <Navbar />
      <div className="cls">
        <Sidebar />
        <div className="content1">
          <b>Account Information</b>
          <div className="acc-info-form">
            <h3>Personal Info</h3>
            <form onSubmit={handleSaveInfo}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
               label="Email"
                variant="outlined"
                value={userInfo.email}
                InputProps={{ readOnly: true }}
                style={{ width: '50%', marginLeft: '50px' }}
              />
              <TextField
                label="Name"
                variant="outlined"
                name="userName"
                value={userInfo.userName}
                onChange={handleInputChange}
                style={{ width: '25%', marginLeft: '50px' }}
              />            
             <h3>Change Password</h3>
                <>
                  <TextField
                    label="Old Password"
                    variant="outlined"
                    type="password"
                    name="oldPassword"
                    value={userInfo.oldPassword}
                    onChange={handleInputChange}
                    style={{ width: '50%', marginLeft: '50px' }}
                  />
                  <TextField
                    label="New Password"
                    variant="outlined"
                    type="password"
                    name="newPassword"
                    value={userInfo.newPassword}
                    onChange={handleInputChange}
                    style={{ width: '50%', marginLeft: '50px' }}
                  />
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    type="password"
                    name="confirmPassword"
                    value={userInfo.confirmPassword}
                    onChange={handleInputChange}
                    style={{ width: '50%', marginLeft: '50px' }}
                  />
                </>       
              <Box>  
                <button className="profile-btn" type="submit">
                  SAVE
                </button>
              </Box>
            </Box>
          </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
export default LinkedHelperAcc;