import React, { useState, useEffect } from "react";
import './pricing.css';
import Header from "./header";
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import ClearIcon from '@mui/icons-material/Clear';
import { getPlans } from "../services/user-service";
import { useNavigate } from 'react-router-dom';

function Pricing() {
  const [selectedDuration, setSelectedDuration] = useState("1");
  const [plans, setPlans] = useState([]);
  const [comparePlans, setComparePlans] = useState([]);
  const [monthsAndDiscounts, setMonthsAndDiscounts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedDuration("1Months");
    fetchAllPlans("1");
  }, []);

  const fetchAllPlans = async (durationKey) => {
    try {
      const data = await getPlans(durationKey);
      setPlans(data.plansList);
      setComparePlans(data.comparePlansList);
      setMonthsAndDiscounts(data.monthsAndDiscounts);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const handleDurationChange = (durationKey) => {
    setSelectedDuration(durationKey);
    fetchAllPlans(durationKey);
  };

  const handleBuyNow = (planId) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      // Redirect to login page
      navigate('/login?redirect=/buyplan');
    } 
  };



  const formatCurrency = (amount) => {
    return amount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' }).slice(1);
  };

  const calculatePerMonth = (basePrice, discount) => {
    const perMonth = basePrice * (1 - discount / 100);
    return formatCurrency(perMonth);
  };

  return (
    <div>
      <Header />
      <div className="pricing-container">
        <table className="pricing-table">
          <thead>
            <tr>
              <td colSpan={plans.length + 1} style={{ textAlign: 'right', paddingRight: '20px', fontSize: '14.5px', color: '#555' }}>
                All prices are exclusive of GST *
              </td>
            </tr>
            <tr>
              <th>Duration</th>
              {plans.map(plan => (
                <th key={plan.plan_id}>{plan.plan_name}</th>
              ))}
            </tr>

            <tr>
              <td>
                <>
                  {monthsAndDiscounts.map((monthData) => (
                    <button key={monthData.according_month}
                      className={`duration-button ${selectedDuration === monthData.according_month ? "active" : ""}`}
                      onClick={() => handleDurationChange(monthData.according_month)}
                    >
                      {monthData.according_month} Months
                      <span className="discount-label">{monthData.discount * 1}% off</span>
                    </button>

                  ))}
                </>
              </td>

              {plans.map(plan => (
                <td key={plan.plan_id}>
                  <div style={{ fontSize: '33px' }}>₹ {plan.price.toLocaleString('en-IN')}</div>
                  <div>/ month</div>
                  <button
                    className="buy-button"
                    onClick={() => handleBuyNow(plan.plan_id)} 
                  >
                    Buy Now
                  </button>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Includes:</th>
              {comparePlans.map(comparePlan => (
                <th key={comparePlan.compare_plan_id}></th>
              ))}
            </tr>
            {["lead_generation", "prospect_identify", "invite_connection", "message_followups", "reply_detection", "linkedin_page_follow_request", "cold_mail_campaign"].map((feature, index) => (
              <tr key={index}>
                <td>{feature.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}</td>
                {comparePlans.map(comparePlan => (
                  <td key={comparePlan.compare_plan_id}>
                    {comparePlan[feature] === true ? <DoneSharpIcon /> : comparePlan[feature] === false ? <ClearIcon /> : comparePlan[feature]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

        </table>
      </div>
    </div>
  );
}

export default Pricing;
