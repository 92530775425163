import React from "react";
import Header from "./header";
import './about.css';
import { LiaLinkedin } from "react-icons/lia";
import img1 from '../image/img1.jpg';
import img2 from '../image/img2.jpg';
import img3 from '../image/img3.jpg';
import img4 from '../image/img4.jpg';
import img5 from '../image/img5.jpg';
import '@fortawesome/fontawesome-free/css/all.min.css';

function About() {
    return (
        <div className="about-container">
            <Header />
            
            <div className="aboutus-img-container">
                {/* <img src={aboutImg} alt="About Us" className="aboutus-img" /> */}
                <div className="about">
                    <div className="about-content">
                        <h2>About Us</h2>
                        <p>In today's fast-paced business landscape, tapping into the vast network of professionals on LinkedIn presents an unparalleled opportunity for targeted <b>B2B Lead Generation</b>. We specialize in leveraging this potential through our innovative Solutions.
                            With the majority of LinkedIn profiles belonging to individuals immersed in various business environments, the platform becomes a goldmine for businesses seeking to connect with their ideal clients. However, the key lies in efficient funnel automation, and that's where <b>ConnectING Prospects</b> Team shines.</p>
                    </div>
                </div>
            </div>
            <div className="team-work">
                <p>Our Team</p>
                </div>
                <div className="team-container">
                    <div className="team-row">
                        <div className="team-box">
                            <img src={img1} alt="Team Member 1" className="team-img" />
                            <div className="team-info">
                                <a href="https://www.linkedin.com/in/nagesh-gavhane-95047a213/" rel="noopener noreferrer">
                                <i className="fab fa-linkedin"/>Nagesh Gavhane</a>
                                <p>CEO and Founder</p>
                            </div>
                        </div>
                        <div className="team-box">
                            <img src={img2} alt="Team Member 2" className="team-img" />
                            <div className="team-info">
                                <a href="https://www.linkedin.com/in/anandsagarshiralkar/"  rel="noopener noreferrer">
                                <i className="fab fa-linkedin"/>Anand Shiralkar</a>
                                <p>Co-founder</p>
                            </div>
                        </div>
                        <div className="team-box">
                            <img src={img3} alt="Team Member 3" className="team-img" />
                            <div className="team-info">
                                <a href="https://www.linkedin.com/in/roopali-kale-%E3%83%AB%E3%83%91%E3%83%AA-%E3%82%AB%E3%83%AC-08b5331a5/" rel="noopener noreferrer">
                                <i className="fab fa-linkedin"/>Roopali Kale</a>
                                <p>Co-founder and COO</p>
                            </div>
                        </div>
                    </div>
                    <div className="team-row">
                        <div className="team-box">
                            <img src={img4} alt="Team Member 4" className="team-img" />
                            <div className="team-info">
                                <a href="https://www.linkedin.com/in/sudhanva-kulkarni-0517b7135/"  rel="noopener noreferrer">
                                <i className="fab fa-linkedin"/>Sudhanva Kulkarni</a>
                                <p>Strategic Advisor</p>
                            </div>
                        </div>
                        <div className="team-box">
                            <img src={img5} alt="Team Member 5" className="team-img" />
                            <div className="team-info">
                                <a href="https://www.linkedin.com/in/ashwini-gavhane-a3b997218/"  rel="noopener noreferrer">
                                <i className="fab fa-linkedin"/>Ashwini Gavhane</a>
                                <p>Research Analyst</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
    )
}
export default About;
