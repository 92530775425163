import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignUpWithEmailAndOTP, UserLogin } from "./services/user-service";
import { doLogin } from "./auth/index";
import './loginPage.css';
import { toast } from "react-toastify";
import loginImage from './image/login_img.PNG';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode as jwt_decode } from "jwt-decode";
import orImage from "./image/OR.png"
import { googleMailRegisterLogin } from "./services/user-service";

function LoginPage() {
  const [activeTab, setActiveTab] = useState('login');
  const [signupForm, setSignupForm] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    enterOTP: '',
  });
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
  });
  const [forgotPasswordForm, setForgotPasswordForm] = useState({
    email: '',
    code: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [isSignupButtonDisabled, setSignupButtonDisabled] = useState(true);
  const [isGetOTPButtonDisabled, setGetOTPButtonDisabled] = useState(true);
  const [otpSentMessage, setOtpSentMessage] = useState('');
  const [sendingOtpMessage, setSendingOtpMessage] = useState('');
  const [showEnterOTP, setShowEnterOTP] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);

  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await UserLogin({
        email: loginForm.email,
        password: loginForm.password,
      });

      if (response) {
        console.log('Login successful:', response);

        const roles = response.roles;

        if (roles && roles.includes('ROLE_ADMIN')) {
          navigate('/datainputpage');
        } else {
          navigate('/dashboard');
        }

        const userDetail = {
          userName: response.userName,
          email: response.email,
          password: response.password,
        };
        localStorage.setItem('user', JSON.stringify(userDetail));
        doLogin(response, () => {
          console.log('Login detail saved to local storage');
          toast.success('Login Success');
        });
      } else {
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error during login', error);

      if (error.response) {
        if (error.response.status === 401) {
          toast.error('Enter the correct password.');
        } else if (error.response.status === 404) {
          toast.error('Enter the correct email.');
        } else {
          toast.error('Error during login. Please try again.');
        }
      } else {
        console.error('Network or other error during login:', error.message);
        toast.error('Login failed: Unable to reach the backend server. Please ensure the server is up and running.');
      }
    }
  };

  const [otpMessage, setOtpMessage] = useState('');
  const [passwordMismatchError, setPasswordMismatchError] = useState('');

  const handleGetOTP = async () => {
    try {
      setSendingOtpMessage('Sending OTP...');
      setOtpMessage('');
      setGetOTPButtonDisabled(true);

      const response = await SignUpWithEmailAndOTP({
        email: signupForm.email,
      });

      if (response) {
        console.log('Response from server:', response);

        setOtpSentMessage(`OTP sent to ${signupForm.email}`);
        setSendingOtpMessage('');
        setShowEnterOTP(true);
        setShowPasswordFields(true);

        setTimeout(() => {
          setOtpMessage('');
          setGetOTPButtonDisabled(false);
        }, 9000);
      } else {
        console.error('Failed to get OTP');
        setOtpMessage('Failed to get OTP. Please try again.');
        setSendingOtpMessage('');
        setGetOTPButtonDisabled(false);
      }
    } catch (error) {
      console.error('Error while getting OTP', error);
      setOtpMessage('Error while getting OTP. Please try again.');
      setSendingOtpMessage('');
      setGetOTPButtonDisabled(false);
    }
  };

  useEffect(() => {
    setSignupButtonDisabled(
      !signupForm.enterOTP.trim() || isGetOTPButtonDisabled
    );
  }, [signupForm.enterOTP, isGetOTPButtonDisabled]);

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    if (signupForm.password !== signupForm.confirmPassword) {
      console.error('Password and Confirm Password do not match.');
      setPasswordMismatchError('Password and Confirm Password do not match.');
      return;
    }
    try {
      const response = await SignUpWithEmailAndOTP({
        email: signupForm.email,
        password: signupForm.password,
        otp: signupForm.enterOTP,
      });

      if (response) {
        console.log('Signup successful.');

        const roles = response.roles;
        if (roles && roles.includes('ROLE_ADMIN')) {
          navigate('/datainputpage');
        } else {
          navigate('/LinkedHelperAcc');
        }
        const userDetail = {
          userName: response.userName,
          email: response.email,
        };

        localStorage.setItem('user', JSON.stringify(userDetail));

        doLogin(response, () => { });
      } else {
        console.error('Failed to signup');
      }
    } catch (error) {
      console.error('Error during signup', error);
    }
  };

  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault();
    console.log('Forgot Password submitted');
    const isPasswordResetSuccessful = true;

    if (isPasswordResetSuccessful) {
      navigate('/LinkedHelperAcc');
    }
  };

  useEffect(() => {
    setGetOTPButtonDisabled(
      !signupForm.email.trim()
    );
  }, [signupForm.email, signupForm.password, signupForm.confirmPassword]);

  useEffect(() => {
    setSignupButtonDisabled(
      !signupForm.enterOTP.trim() || isGetOTPButtonDisabled
    );
  }, [signupForm.enterOTP, isGetOTPButtonDisabled]);

  const isLoginButtonDisabled =
    activeTab === 'login' &&
    (!loginForm.email.trim() || !loginForm.password.trim());

  const isGoogleLoginVisible = activeTab === 'login' || activeTab === 'signup';

  const authGoogleLogin = (token) => {
    var decoded = jwt_decode(token);
    let passingData = {
      "user_name": decoded.email,
      "email": decoded.email,
    }
    googleMailRegisterLogin(passingData).then((jwtTokendata) => {
      doLogin(jwtTokendata, () => {
        console.log("Login detail saved to local storage");
        navigate("dashboard");
        toast.success("Login Success");
      })
    }).catch(error => {
      if (error.response.status === 401) {
        // setFormError(error.response.data);
      } else {
        // setFormError("Something Went Wrong");
      }
    })
  }

  return (
    <div className="login-page-container">
      <div className="login-page">
        <div className="image-section">
          <img src={loginImage} alt="Login Image" />
        </div>

        <div className="form-section">
          <form
            onSubmit={
              activeTab === 'login'
                ? handleLoginSubmit
                : activeTab === 'signup'
                  ? handleSignupSubmit
                  : handleForgotPasswordSubmit
            }
          >
            {activeTab === 'login' && (
              <div>
                <span className="logintitle">Sign In</span>
                <div className="login-eml">
                  <TextField
                    type="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={loginForm.email}
                    onChange={(e) => setLoginForm({ ...loginForm, email: e.target.value })}
                  />
                </div>
                <div className="login-pass">
                  <TextField
                    type="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    value={loginForm.password}
                    onChange={(e) => setLoginForm({ ...loginForm, password: e.target.value })}
                  />
                </div>
                <p className="forgot-password-link" style={{ color: '#009bff', fontWeight: '600', marginLeft: '160px', marginTop: '10px', cursor: 'pointer' }} onClick={() => handleTabChange('forgot')}>Forgot Your Password?</p>
                <Button
                  type="submit"
                  variant="contained"
                  className="login-btn button"
                  disabled={isLoginButtonDisabled}
                >
                  Login
                </Button>
                <p className="forgot-password-link" style={{ color: 'black', fontWeight: '400', marginTop: '20px', marginRight: '30px', cursor: 'pointer' }} onClick={() => handleTabChange('signup')}>Don't Have an Account? <b>Sign Up</b> </p>
              </div>
            )}

            {activeTab === 'signup' && (
              <div>
                <span className="signuptitle">Sign Up</span>
                <div className="login-eml">
                  <TextField
                    type="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={signupForm.email}
                    onChange={(e) => setSignupForm({ ...signupForm, email: e.target.value })}
                  />
                </div>
                {showEnterOTP && (
                  <div className="login-pass">
                    <TextField
                      type="text"
                      label="Enter OTP"
                      variant="outlined"
                      fullWidth
                      value={signupForm.enterOTP}
                      onChange={(e) => setSignupForm({ ...signupForm, enterOTP: e.target.value })}
                    />
                  </div>
                )}
                {showPasswordFields && (
                  <div className="password-container">
                    <TextField
                      type="password"
                      label="Password"
                      variant="outlined"
                      fullWidth
                      value={signupForm.password}
                      onChange={(e) => setSignupForm({ ...signupForm, password: e.target.value })}
                    />
                    <TextField
                      type="password"
                      label="Confirm Password"
                      variant="outlined"
                      fullWidth
                      value={signupForm.confirmPassword}
                      onChange={(e) => setSignupForm({ ...signupForm, confirmPassword: e.target.value })}
                    />
                  </div>
                )}
                <p
                  style={{
                    color: '#009bff',
                    fontWeight: '600',
                    marginLeft: '150px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleTabChange('login')}
                >
                  Already Have an Account?
                </p>
                {otpMessage && <p className="otp-message">{otpMessage}</p>}
                {passwordMismatchError && <p className="otp-message">{passwordMismatchError}</p>}
                {sendingOtpMessage && <p className="sending-otp-message">{sendingOtpMessage}</p>}
                <div className="button-container">
                  <Button
                    type="submit"
                    variant="contained"
                    className="signup-btn"
                    disabled={isSignupButtonDisabled}
                  >
                    Sign Up
                  </Button>
                  <Button
                    className="get-otp"
                    variant="contained"
                    disabled={isGetOTPButtonDisabled}
                    onClick={handleGetOTP}
                  >
                    Get OTP
                  </Button>
                </div>
                {otpSentMessage && <p className="otp-sent-message">{otpSentMessage}</p>}
              </div>
            )}
            <div className="google-login-container">
              {activeTab !== 'forgot' && (
                <img className="orimage1" src={orImage} alt="Or" />
              )}
              {isGoogleLoginVisible && (
                <div className="google-content">
                  <GoogleLogin
                    onSuccess={credentialResponse => {
                      authGoogleLogin(credentialResponse.credential);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                </div>
              )}
            </div>
            {activeTab === 'forgot' && (
              <div>
                <span className="resettitle">Password Reset</span>
                <div className="reset-field">
                  <TextField
                    type="email"
                    label=" Enter Email"
                    variant="outlined"
                    fullWidth
                    value={forgotPasswordForm.email}
                    onChange={(e) => setForgotPasswordForm({ ...forgotPasswordForm, email: e.target.value })}
                  />
                </div>
                <div >
                  <button className="reset-passbtn">
                    Reset
                  </button>
                </div>
                <p style={{ color: '#009bff', fontWeight: '600', marginLeft: '200px', cursor: 'pointer', marginBottom: '20px' }} onClick={() => handleTabChange('login')}>Go To Login</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;