import React from 'react';
import AdminSidepanel from '../Sidebar/AdminSidepanel';
import './execumngt.css';

function execmngt() {
    return(
        <div className="exemngt-title">
        <AdminSidepanel />
      <h2>Marketing Executive Management</h2>
        </div>
    )
};
export default execmngt;
