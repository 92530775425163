// user-service.js
import { myAxios } from "./helper";

export const SignUpWithEmailAndOTP = async (user) => {
  const response = await myAxios.post('/api/signup', user);
  return response.data;
}

export const sendContactDetails = async (contactDetails) => {
  const response = await myAxios.post('/api/contactdetails', contactDetails);
  return response.data;
}
export const getPlans = async (duration) => {
  try {
    const response = await myAxios.get(`/api/plans/user/plans?month=${duration}`);
   
    return response.data;
  } catch (error) {
    console.error("Error fetching plans:", error);
    throw error;
  }
};

export const UserLogin = async (loginDetails) => {
  const response = await myAxios.post('/api/v1/auth/login', loginDetails, { timeout: 10000 });
  return response.data;
}
export const googleMailRegisterLogin=async (token)=>{
  const response = await myAxios.post('/api/v1/auth/google-auth', token);
  return response.data;
}