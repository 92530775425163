import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material';
import AdminSidepanel from '../Sidebar/AdminSidepanel';
import Navbar from '../Sidebar/Navbar';
import './execumngt.css';
import { GetTransactionsData } from '../services/plan-service';

function AccountsLedger() {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetTransactionsData();
        setTransactions(data);
      } catch (error) {
        // Handle error
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <div>
      <Navbar />
      <AdminSidepanel />
      
      <div className="table-container-wrapper">
      <div>
        <h2>Transaction List</h2>
      </div>
        <div className="table-container">
          <TableContainer component={Paper}>
            <Table className="ledger-table">
              <TableHead>
                <TableRow>
                  <TableCell>Transaction ID</TableCell>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Payment ID</TableCell>
                  <TableCell>Customer ID</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Transaction Date</TableCell>
                  <TableCell>Transaction Details</TableCell>
                  <TableCell>Transaction Status</TableCell>
                  <TableCell>Plan Name</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((transaction,user) => (
                  <TableRow key={transaction.transaction.transactionId}>
                    <TableCell>{transaction.transaction.transactionId}</TableCell>
                    <TableCell>{transaction.transaction.orderId}</TableCell>
                    <TableCell>{transaction.transaction.paymentId}</TableCell>      
                    <TableCell>{transaction.user_id}</TableCell>
                   <TableCell>{transaction.email}</TableCell>
                    <TableCell>{transaction.transaction.transactionDate}</TableCell>
                    <TableCell>{transaction.transaction.transactionDetails}</TableCell>
                    <TableCell>{capitalizeFirstLetter(transaction.transaction.transactionStatus)}</TableCell>
                    <TableCell>{transaction.transaction.plan.plan_name}</TableCell>
                    <TableCell>{transaction.transaction.plan.price}</TableCell>
                  </TableRow>
                ))}
               
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        className="accledger-pagination"
        component="div"
        count={transactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default AccountsLedger;
