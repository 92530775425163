import React from 'react';
import Header from './header';
import './solution.css';

const Solutions = () => {
  return (
    <div>
      <Header />
      <div className="solution-container">
        <div className="solution-content">
          <p>Where Business Challenges Bow Down To Solutions.</p>
        </div>
        <div className="solution-boxes">
          <div className="solution-btn">
            <h1>Lead Generation</h1>
            <ul>
              <li>Discover effective lead generation solutions tailored to your business needs.</li>
              <li>Our expertise ensures targeted outreach and efficient conversion.</li>
              <li>Maximizing your potential for growth.</li>
              <li>Harness the power of our innovative strategies to propel your business forward.</li>
            </ul>
          </div>

          <div className="solution-btn">
            <h1>Lead Management</h1>
            <ul>
              <li>Elevate your lead management with our comprehensive solution.</li>
              <li>Streamline your processes, from capturing leads to nurturing them into valuable customers.</li>
              <li>We offer intuitive solutions and analytics to optimize your sales pipeline.</li>
              <li>Empowering your team to drive results efficiently.</li>
              <li>Take control of your leads and unlock your business's full potential with our advanced lead management solution.</li>
            </ul>
          </div>

          <div className="solution-btn">
            <h1>Data Solutions</h1>
            <ul>
              <li>Unlock the power of data with our comprehensive B2B solution.</li>
              <li>Access accurate, up-to-date information on businesses and decision-makers to fuel your growth strategies.</li>
              <li>Our platform offers advanced analytics and customizable datasets to drive informed decision-making and maximize ROI.</li>
              <li>Empower your business with reliable B2B data solutions tailored to your specific needs and stay ahead of the competition.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
