import React from "react";
import './privacypolicy.css';
import Header from "./header";
import privacypolicy from '../image/privacypolicy-img2.jpg';

function Privacypolicy() {
    return (
        <div className="about-container">
            <Header />
            <div className="aboutus-img-container">
                <img src={privacypolicy} alt="About Us" className="aboutus-img" />
                <div className="policy-page">
                    <div className="about-content">
                        <h2>Privacy Policy</h2>
                        <p style={{ textAlign: 'justify'}}>Thank you for choosing <b>ConnectING Prospects</b> for your lead generation services. We are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide us when using our website and services. By accessing or using our website or services, you agree to the terms of this Privacy Policy.</p>
                    </div>
                </div>
            </div>
            <div className="policy-otherInfo">
                <h3>Information We Collect:</h3>
                <ul>
                    <li><strong>Personal Information:</strong>
                        <p>
                            When you use our website or services, we may collect personal information such as your name, email address, phone number, company name, job title, and any other information you provide voluntarily.
                        </p>
                    </li>
                    <li><strong>Automatically Collected Information:</strong>
                        <p> We may also collect certain information automatically when you visit our website, including your IP address, browser type, operating system, referring URLs, page views, and other browsing information.</p>
                    </li>
                </ul>
                <h3>How We Use Your Information:</h3>
                <ul>
                    <li><strong>Providing Services:</strong>
                        <p>We use the information we collect to provide you with the services you request, including lead generation services, and other related services.
                        </p>
                    </li>
                    <li><strong>Communications:</strong>
                        <p>
                            We may use your contact information to communicate with you about our services, updates, promotions, and other relevant information. You can opt-out of receiving these communications at any time.
                        </p>
                    </li>
                    <li><strong>Improving Our Services:</strong>
                        <p>
                            We may use the information to analyze trends, monitor the effectiveness of our website and services, and improve our offerings.
                        </p>
                    </li>
                </ul>

                <h3>Sharing Your Information:</h3>
                <ul>
                    <li><strong>Third-Party Service Providers:</strong>
                        <p>
                            We may share your information with third-party service providers who assist us in providing our services, such as email service providers, analytics providers, and payment processors. These service providers are obligated to maintain the confidentiality of your information and are not authorized to use it for any other purpose.
                        </p>
                    </li>
                    <li><strong>Legal Compliance:</strong>
                    <p>
                    We may disclose your information when required by law, regulation, or legal process, or to protect our rights or the rights of others.
                    </p>
                    </li>
                </ul>

                <h3>Data Security:</h3>
                <p>We take reasonable measures to protect the information we collect from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

                <h3>Your Choices:</h3>
                <p>You have the right to access, update, or delete your personal information at any time. You can also opt-out of receiving promotional communications from us by following the instructions provided in the communication or by contacting us directly.</p>

                <h3>Changes to this Privacy Policy:</h3>
                <p>We reserve the right to update or change this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on this page. We encourage you to review this Privacy Policy periodically for any changes.</p>

                <h3>Contact Us:</h3>
                <p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at support@connectingprospects.com</p>

            </div>
        </div>
    );
}
export default Privacypolicy;
