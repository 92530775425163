import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import './dashboard.css';
import Chart from 'react-apexcharts';
import 'react-datepicker/dist/react-datepicker.css';
import Navbar from '../Sidebar/Navbar';
import { GetDailyActivityData, GetInviteAndAcceptData, GetMessageReplyData } from '../services/plan-service';
import DatePicker from 'react-datepicker';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Dashboard = () => {
  const chartTypes = {
    dailyActivity: 'dailyActivity',
    invitedAccepted: 'invitedAccepted',
    messagedReplied: 'messagedReplied',
  };

  const [state, setState] = useState({
    options: {
      colors: [
        '#4272e3',
        '#687481',
        'orange',
        'blue',
        'red',
        'pink',
      ],
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: [],
      },
      plotOptions: {
        bar: {
          columnWidth: '28%',
        },
      },
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: false, // Disable data labels on the chart bars
      },
    },
    series: [],
    totalInvites: 0,
    totalAccepted: 0,
    acceptanceRate: 0,
    totalMessages: 0,
    totalReplies: 0,
    replyRate: 0,
    graphDataAvailable: true,
  });

  const [chartType, setChartType] = useState(chartTypes.invitedAccepted);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showGoButton, setShowGoButton] = useState(true);
  const [showClearButton, setShowClearButton] = useState(false);

  useEffect(() => {
    const currentMonthStart = new Date();
    currentMonthStart.setDate(1);
    const currentMonthEnd = new Date(currentMonthStart);
    currentMonthEnd.setMonth(currentMonthEnd.getMonth() + 1);

    setStartDate(currentMonthStart);
    setEndDate(currentMonthEnd);
    updateSeries(chartType, currentMonthStart, currentMonthEnd);
  }, []);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const updateSeries = async (type, startDate, endDate) => {
    try {
      const data =
        type === chartTypes.dailyActivity
          ? await GetDailyActivityData(startDate, endDate)
          : type === chartTypes.invitedAccepted
            ? await GetInviteAndAcceptData(startDate, endDate)
            : await GetMessageReplyData(startDate, endDate);

      if (data.status === 204) {
        setSnackbarMessage(data.message);
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
        setState(prevState => ({
          ...prevState,
          graphDataAvailable: false,
        }));
        return;
      } else if (data.status === 403) {
        setSnackbarMessage('You do not have an active plan. Please buy/renew a plan.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setState(prevState => ({
          ...prevState,
          graphDataAvailable: false,
        }));
        return;
      }

      const chartData =
        type === chartTypes.dailyActivity
          ? data.map((entry) => ({
            date: entry.date,
            value1: entry.invitemessageCount,
          }))
          : type === chartTypes.invitedAccepted
            ? data.inviteAndAcceptList.map((entry) => ({
              date: entry.date,
              value1: entry.inviteProfile,
              value2: entry.acceptProfile,
            }))
            : data.messageAndReplyList.map((entry) => ({
              date: entry.date,
              value1: entry.messageCount,
              value2: entry.replyCount,
            }));

      const seriesData =
        type === chartTypes.invitedAccepted
          ? [
            { name: 'Invite Profile', data: chartData.map((entry) => entry.value1) },
            { name: 'Accept Profile', data: chartData.map((entry) => entry.value2) },
          ]

          : type === chartTypes.dailyActivity
            ? [{ name: 'Invite + Message Count', data: chartData.map((entry) => entry.value1) }]
            : [
              { name: 'Message Count', data: chartData.map((entry) => entry.value1) },
              { name: 'Reply Count', data: chartData.map((entry) => entry.value2) },
            ];

      setState(prevState => ({
        ...prevState,
        series: seriesData,
        options: {
          ...prevState.options,
          xaxis: {
            categories: chartData.map((entry) => {
              const formattedDate = new Date(entry.date);
              return formattedDate.toLocaleString('default', { month: 'short', day: 'numeric' });
            }),
          },
        },
        graphDataAvailable: seriesData.length > 0, // Ensure graphDataAvailable is true if there is data
      }));

      if (type === chartTypes.invitedAccepted) {
        const totalInvites = data.totalInvites || 0;
        const totalAccepted = data.totalAccepted || 0;
        const acceptanceRate = data.acceptanceRate || 0;

        setState(prevState => ({
          ...prevState,
          totalInvites,
          totalAccepted,
          acceptanceRate,
        }));
      } else if (type === chartTypes.messagedReplied) {
        const totalMessages = data.totalMessages || 0;
        const totalReplies = data.totalReplies || 0;
        const replyRate = data.replyRate || 0;

        setState(prevState => ({
          ...prevState,
          totalMessages,
          totalReplies,
          replyRate,
        }));
      }
    } catch (error) {
      console.error('Error:', error.message);
      setSnackbarMessage('You do not have an active plan. Please buy/renew a plan.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setState(prevState => ({
        ...prevState,
        graphDataAvailable: false,
      }));
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleGoButtonClick = () => {
    updateSeries(chartType, startDate, endDate);
    setShowGoButton(false);
    setShowClearButton(true);
  };

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
    setShowGoButton(true);
    setShowClearButton(false);

    const currentMonthStart = new Date();
    currentMonthStart.setDate(1);
    const currentMonthEnd = new Date(currentMonthStart);
    currentMonthEnd.setMonth(currentMonthEnd.getMonth() + 1);

    setStartDate(currentMonthStart);
    setEndDate(currentMonthEnd);

    updateSeries(chartType, currentMonthStart, currentMonthEnd);
  };

  return (
    <div className="dashboard-page">
      <Navbar />
      <Sidebar />
      <div className="dashboard-content">
        {/* <div className="square-box"> */}
        <div className="date-picker-container">
          <div className="datapicker_boxLabel">
            <label>Date Range:</label>
          </div>
          <div>
            <span style={{ color: 'black', fontSize: '17px' }}>From Date   </span>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              // style={{padding: '10px'}}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="dd-MM-yyyy"
            />
          </div>
          <div>
            <span style={{ color: 'black', fontSize: '17px' }}>To Date  </span>
            <DatePicker
              selected={endDate}
              onChange={(date) => {
                setEndDate(date);
              }}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="dd-MM-yyyy"
            />
          </div>
          {startDate && endDate && showGoButton && (
            <button className="go-btn" onClick={handleGoButtonClick}>
              Go
            </button>
          )}
          {showClearButton && (
            <button className="clear-button" onClick={handleClearDates}>
              Clear
            </button>
          )}
        </div>
        {/* </div> */}
        {/* </div> */}
        {/* <div> */}

        {/* status buttons         */}
        <div className="status">
          <button
            className={chartType === chartTypes.dailyActivity ? 'active' : ''}
            onClick={() => {
              setChartType(chartTypes.dailyActivity);
              updateSeries(chartTypes.dailyActivity, startDate, endDate);
            }}
          >
            Daily Activity
          </button>
          <button
            className={chartType === chartTypes.invitedAccepted ? 'active' : ''}
            onClick={() => {
              setChartType(chartTypes.invitedAccepted);
              updateSeries(chartTypes.invitedAccepted, startDate, endDate);
            }}
          >
            Invited and Accepted
          </button>
          <button
            className={chartType === chartTypes.messagedReplied ? 'active' : ''}
            onClick={() => {
              setChartType(chartTypes.messagedReplied);
              updateSeries(chartTypes.messagedReplied, startDate, endDate);
            }}
          >
            Messaged and Replied
          </button>
        </div>
        {/* </div> */}

        <div>
          {state.graphDataAvailable && state.series.length > 0 ? (
            <div className="additional-info1">
              {chartType === chartTypes.invitedAccepted && (
                <p>Total Invites: {state.totalInvites} &nbsp;|&nbsp; Total Accepted: {state.totalAccepted} &nbsp;|&nbsp; Acceptance Rate: {state.acceptanceRate.toFixed(2)}%</p>
              )}
              {chartType === chartTypes.messagedReplied && (
                <p>Total Messages: {state.totalMessages} &nbsp;|&nbsp; Total Replied Received: {state.totalReplies} &nbsp;|&nbsp; Reply Rate: {state.replyRate.toFixed(2)}%</p>
              )}
              <Chart options={state.options} series={state.series} type="bar" width="650" height="350" />
            </div>
          ) : (
            <p>No data available for the selected date range.</p>
          )}
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </div>
    </div>

  );
};
export default Dashboard;
