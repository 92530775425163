import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  CircularProgress,
  Snackbar,
  Backdrop,
  Tooltip,
} from '@mui/material';
import { GetAllProjectSpecifications, AddProjectSpecification, updateExcelLink } from "../services/plan-service";
import Adminsidepanel from "../Sidebar/AdminSidepanel";
import Navbar from "../Sidebar/Navbar";
import './campaign.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Campaign() {
  const [specifications, setSpecifications] = useState([]);
  const [filteredSpecifications, setFilteredSpecifications] = useState([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowsWithHiddenIcons, setRowsWithHiddenIcons] = useState([]);
  const [acceptedSpecifications, setAcceptedSpecifications] = useState([]);
  const [alreadyAcceptedDialogOpen, setAlreadyAcceptedDialogOpen] = useState(false);
  const [alreadyDeclinedDialogOpen, setAlreadyDeclinedDialogOpen] = useState(false);
  const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
  const [excelLinkDialogOpen, setExcelLinkDialogOpen] = useState(false);
  const [excelLink, setExcelLink] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState(null);


  useEffect(() => {
    async function fetchSpecifications() {
      try {
        const data = await GetAllProjectSpecifications();
        setSpecifications(data);
        setFilteredSpecifications(data);
        const rowsToHideIcons = data.filter(row => row.projectSpecification.active === "true");
        setRowsWithHiddenIcons(rowsToHideIcons.map(row => row.projectSpecification.id));
      } catch (error) {
        console.error("Error fetching specifications:", error);
      }
    }
    fetchSpecifications();
  }, []);

  const handleFilterChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    const filteredData = specifications.filter(user =>
      Object.values(user).some(value =>
        String(value).toLowerCase().includes(searchText)
      )
    );
    setFilteredSpecifications(filteredData);
    setPage(0);
  };

  const handleViewClick = (user) => {
    setSelectedUser(user);
    setViewModalOpen(true);
  };

  const handleViewModalClose = () => {
    setViewModalOpen(false);
  };

  const handleAcceptClick = async (user) => {
    try {
      if (user.projectSpecification.active === "true") {
        setAlreadyAcceptedDialogOpen(true);
        return;
      }
      if (acceptedSpecifications.includes(user.projectSpecification.id)) {
        alert("You have already accepted this specification.");
        return;
      }
      setLoadingAccept(true);
      setBackdropOpen(true);
      try {
        const response = await AddProjectSpecification({
          projectId: user.projectSpecification.id,
          userId: user.email,
          setTrueOrFalse: "true",
        });
        if (response && response.id) {
          toast.success('Specification Accepted.');
          setAcceptedSpecifications([...acceptedSpecifications, user.projectSpecification.id]);
        } else {
          console.error("Error updating specification on the server.");
        }
      } catch (error) {
        console.error("Error updating specification on the server:", error);
      }

      setLoadingAccept(false);
      setBackdropOpen(false);
    } catch (error) {
      console.error("Error accepting specification:", error);
      setLoadingAccept(false);
      setBackdropOpen(false);
    }
  };

  const handleDeclineClick = (user) => {
    setSelectedUser(user);

    if (user.projectSpecification.active === "false") {
      setAlreadyDeclinedDialogOpen(true);
    } else {
      setOpenDeclineDialog(true);
    }
  };

  const handleAlreadyDeclinedClose = () => {
    setAlreadyAcceptedDialogOpen(false);
  };

  const handleDeclineConfirm = async () => {
    try {
      setLoadingAccept(true);
      setBackdropOpen(true);
      try {
        const response = await AddProjectSpecification({
          projectId: selectedUser.projectSpecification.id,
          userId: selectedUser.email,
          setTrueOrFalse: "false",
        });

        if (response && response.id) {
          toast.success('Specification Rejected.');
          setRowsWithHiddenIcons([...rowsWithHiddenIcons, selectedUser.projectSpecification.id]);
        } else {
          console.error("Error declining specification on the server.");
        }
      } catch (error) {
        console.error("Error declining specification on the server:", error);
      }

      setLoadingAccept(false);
      setBackdropOpen(false);
      setOpenDeclineDialog(false);
    } catch (error) {
      console.error("Error declining specification:", error);
      setLoadingAccept(false);
      setBackdropOpen(false);
      setOpenDeclineDialog(false);
    }
  };

  const showDialog = (message) => {
    return new Promise((resolve) => {
      const confirmed = window.confirm(message);
      resolve(confirmed);
    });
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
  };
  const handleExcelLinkClick = (projectId) => {
    setSelectedProjectId(projectId);
    setExcelLinkDialogOpen(true);
  };

  const handleExcelLinkDialogClose = () => {
    setExcelLinkDialogOpen(false);
    setExcelLink('');
  };
  const handleExcelLinkSubmit = async () => {
    try {
      const response = await updateExcelLink({
        projectId: selectedProjectId,
        setTrueOrFalse: "true",
        excelLink: excelLink,
      });
      if (response && response.id) {
        toast.success('Excel link added successfully.');
        console.log(response);
        // Refresh the specifications to reflect the updated Excel link
        // fetchSpecifications();
      } else {
        console.error("Error updating specification on the server.");
      }
    } catch (error) {
      console.error("Error updating specification on the server:", error);
    }
    handleExcelLinkDialogClose();
  };

  return (
    <Box>
      <Navbar />
      <Adminsidepanel />
      <Box className="campaignlist">
        <h2 className="specification-title">All Specifications</h2>
        <TextField
          variant="outlined"
          margin="normal"
          placeholder="Enter customer email"
          onChange={handleFilterChange}
        />

        <Dialog
          open={alreadyDeclinedDialogOpen}
          onClose={() => setAlreadyDeclinedDialogOpen(false)}
        >
          <DialogTitle>This specification is already declined</DialogTitle>
          <DialogActions>
            <Button onClick={() => setAlreadyDeclinedDialogOpen(false)} variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={alreadyAcceptedDialogOpen}
          onClose={() => setAlreadyAcceptedDialogOpen(false)}
        >
          <DialogTitle>This specification is already accepted</DialogTitle>
          <DialogActions>
            <Button onClick={() => setAlreadyAcceptedDialogOpen(false)} variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDeclineDialog}
          onClose={() => setOpenDeclineDialog(false)}
        >
          <DialogTitle>Are you sure you want to decline?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpenDeclineDialog(false)} variant="outlined">
              No
            </Button>
            <Button onClick={handleDeclineConfirm} variant="contained" color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <div className="cust-speci-list">

          <TableContainer component={Paper}>
          <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>  Id  </TableCell>
                  <TableCell>Created On</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Job Title</TableCell>
                  {/* <TableCell>Suspect Name</TableCell> */}
                  <TableCell>Industry</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Add Excel Link</TableCell>

                </TableRow>
              </TableHead>

              <TableBody>
                {filteredSpecifications
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.user_id}</TableCell>

                      <TableCell>{formatDate(user.projectSpecification.created_At)}</TableCell>
                      <TableCell>{user.projectSpecification.company_name}</TableCell>
                      <TableCell>{user.projectSpecification.job_title}</TableCell>
                      {/* <TableCell>{user.projectSpecification.suspect_Name}</TableCell> */}
                      <TableCell>{user.projectSpecification.industry}</TableCell>
                      <TableCell>
                        {user.projectSpecification.active === "true" && (
                          <b>
                            Accepted
                          </b>
                        )}
                        {user.projectSpecification.active === "false" && (
                          <b>Rejected</b>
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => handleViewClick(user)}
                          variant="contained"
                          style={{ background: 'white', color: 'black' }}
                        >
                          <Tooltip title="Detail Information" arrow>
                            <RemoveRedEyeIcon style={{ color: 'black' }} />
                          </Tooltip>

                        </Button>
                        {user.projectSpecification.active === "requested" && (
                          <>
                            <Button
                              onClick={() => handleAcceptClick(user, index)}
                              variant="outlined"
                              style={{
                                color: acceptedSpecifications.includes(user.projectSpecification.id) ? 'black' : 'black',
                                border: `3px solid ${acceptedSpecifications.includes(user.projectSpecification.id) ? 'green' : 'grey'}`,
                                margin: '5px',
                              }}
                              disabled={loadingAccept}
                            >
                              <Tooltip title="Accept Specification" arrow>
                                <CheckCircleOutlineIcon style={{ marginRight: '5px' }} />
                              </Tooltip>
                              {/* {acceptedSpecifications.includes(user.projectSpecification.id) ? 'Accepted' : 'Accept'} */}
                            </Button>
                            <Button
                              onClick={() => handleDeclineClick(user)}
                              variant="contained"
                              style={{ background: 'white', color: 'black' }}
                            >
                              <Tooltip title="Decline Specification" arrow>
                                <HighlightOffIcon style={{ marginRight: '5px' }} />
                              </Tooltip>

                            </Button>
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                      {user.projectSpecification.excelLink ? (
                        <Button
                          onClick={() => window.open(user.projectSpecification.excelLink, '_blank')}
                          variant="outlined"
                          style={{ color: 'black' }}
                        >
                          View
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleExcelLinkClick(user.projectSpecification.id)}
                          variant="outlined"
                          style={{ color: 'black' }}
                        >
                          Add
                        </Button>
                      )}
                    </TableCell>

                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
       <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredSpecifications.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Dialog
          open={viewModalOpen}
          onClose={handleViewModalClose}
          aria-labelledby="details-dialog-title"
          aria-describedby="details-dialog-description"
          PaperProps={{
            style: {
              maxHeight: '106vh',
            },
          }}
        >
          <DialogTitle className="dialogue-title-spe"><b>Customer Specification Details</b></DialogTitle>
          <DialogContent>
            {selectedUser && (
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Location</TableCell>
                      <TableCell>{selectedUser.projectSpecification.location}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Created On</TableCell>
                      <TableCell>{formatDate(selectedUser.projectSpecification.created_At)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Department</TableCell>
                      <TableCell>{selectedUser.projectSpecification.department}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Suspect Name</TableCell>
                      <TableCell>{selectedUser.projectSpecification.suspect_Name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Job Title</TableCell>
                      <TableCell>{selectedUser.projectSpecification.job_title}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Employee Size</TableCell>
                      <TableCell>{selectedUser.projectSpecification.employee_Size}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Industry</TableCell>
                      <TableCell>{selectedUser.projectSpecification.industry}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Revenue</TableCell>
                      <TableCell>{selectedUser.projectSpecification.revenue}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Company Name</TableCell>
                      <TableCell>{selectedUser.projectSpecification.company_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Seniority Level</TableCell>
                      <TableCell>{selectedUser.projectSpecification.seniority_level}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </DialogContent>
          <DialogActions>
            {selectedUser?.projectSpecification.active === "true" && (
              <Button autoFocus>
                Accepted
              </Button>
            )}
            {selectedUser?.projectSpecification.active === "false" && (
              <Button autoFocus>
                Rejected
              </Button>
            )}
            <Button onClick={handleViewModalClose} autoFocus>
              Close
            </Button>
            {selectedUser?.projectSpecification.active === "requested" && (
              <>
                <Button
                  onClick={() => handleAcceptClick(selectedUser)}
                  disabled={loadingAccept}
                >
                  {acceptedSpecifications.includes(selectedUser.projectSpecification.id) ? 'Accepted' : 'Accept'}
                </Button>
                <Button
                  onClick={() => handleDeclineClick(selectedUser)}
                >
                  Decline
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          open={excelLinkDialogOpen}
          onClose={handleExcelLinkDialogClose}
        >
          <DialogTitle>Enter the Excel link:</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Excel Link"
              type="url"
              fullWidth
              value={excelLink}
              onChange={(e) => setExcelLink(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleExcelLinkDialogClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleExcelLinkSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={loadingAccept}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
export default Campaign;
