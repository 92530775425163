import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './header.css';
import logo from '../image/new_logo.png';
import { FaBars, FaTimes } from 'react-icons/fa'; // Add icons for hamburger and close

function Header() {
  const location = useLocation();
  const showNavbar = location.pathname !== '/login' 
    && location.pathname !== '/dashboard' 
    && location.pathname !== '/datainputpage' 
    && location.pathname !== '/LinkedHelperAcc' 
    && location.pathname !== '/Adminpanel' && location.pathname !== '/execmngt'  
    && location.pathname !== '/AccountsLedger' && location.pathname !== '/buyplan' 
    && location.pathname !== '/specifications' && location.pathname !== '/campaign'
    && location.pathname !== '/createplan';

  const [activeLink, setActiveLink] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
    setMenuOpen(false); // Close the menu on link click
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    showNavbar && (
      <div className="navbar">
        <div className="header">
          <div className="header__left">
            <NavLink to="/" activeClassName="active-link">
              <img src={logo} alt="ConnectIn Logo" className="header__logo-st" />
            </NavLink>
          </div>
          <div className="header__center-container">
            <div className={`header__center ${menuOpen ? 'open' : ''}`}>
              <NavLink
                to="/About"
                activeClassName="active-link"
                onClick={() => handleNavLinkClick('/About')}
                className={activeLink === '/About' ? 'active-link' : ''}
              >
                About
              </NavLink>
              <NavLink
                to="/Solutions"
                activeClassName="active-link"
                onClick={() => handleNavLinkClick('/Solutions')}
                className={activeLink === '/Solutions' ? 'active-link' : ''}
              >
                Solution
              </NavLink>
              <NavLink
                to="/Pricing"
                activeClassName="active-link"
                onClick={() => handleNavLinkClick('/Pricing')}
                className={activeLink === '/Pricing' ? 'active-link' : ''}
              >
                Pricing
              </NavLink>
              <NavLink
                to="/contactus"
                activeClassName="active-link"
                onClick={() => handleNavLinkClick('/contactus')}
                className={activeLink === '/contactus' ? 'active-link' : ''}
              >
                Contact Us
              </NavLink>
            </div>
            <div className={`header__right ${menuOpen ? 'open' : ''}`}>
              <div className="contact-no-link">Contact: (+91) 82753 87644</div>
              <div className="header__login_signup">
                <p className="header__login"> 
                  <NavLink
                    to="/login"
                    activeClassName="active-link"
                    onClick={() => handleNavLinkClick('/login')}
                    className={activeLink === '/login' ? 'active-link' : ''}
                  >
                    Log in
                  </NavLink>
                </p>
                <p className="header__signup"> 
                  <NavLink
                    to="/signup"
                    activeClassName="active-link"
                    onClick={() => handleNavLinkClick('/signup')}
                    className={activeLink === '/signup' ? 'active-link' : ''}
                  >
                    Sign up
                  </NavLink>
                </p>
              </div>
            </div>
            <div className="contactnu">Contact: (+91) 82753 87644</div>
            <div className="header__menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
              {menuOpen ? <FaTimes /> : <FaBars />}
            </div>
            
          </div>
          
        </div>
      </div>
    )
  );
}

export default Header;
