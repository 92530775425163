import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Header/header';
import LoginPage from './Loginpage';
import Dashboard from './clientside/dashboard';
import Datainput from './admincontrol/Datainput';
import LinkedHelperAcc from './clientside/Linkedhelperacc';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './Sidebar/Sidebar';
import Adminsidepanel from './Sidebar/AdminSidepanel';
// import Adminpanel from './admincontrol/Adminpanel';
import Execmngt from './admincontrol/execmngt';
import AccountsLedger from './admincontrol/AccountsLedger';
import Buyplan from './clientside/buyplan';
import Specifications from './clientside/specifications';
import Campaign from './admincontrol/campaign';
import Createplans from './admincontrol/createplan';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Home from './Header/home';
import About from './Header/About';
import Solutions from './Header/Solutions';
import Pricing from './Header/Pricing';
import Contactus from './Header/contactus';
import Footer from './Header/Footer';
import Privacypolicy from './Header/Privacypolicy';
function App() {
  return (
    <>
      <GoogleOAuthProvider clientId=" 971135317423-o09m23vigsqk552sm4beh5jcu6u8ulm6.apps.googleusercontent.com">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Solutions" element={<Solutions />} />
            <Route path="/Pricing" element={<Pricing />} />
            <Route path="/contactus" element={<Contactus />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/sidebar" element={<Sidebar />} />
            <Route path="/Adminsidepanel" element={<Adminsidepanel />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/datainputpage" element={<Datainput />} />
            <Route path="/campaign" element={<Campaign />} />
            <Route path="/createplan" element={<Createplans />} />
            <Route path="/linkedhelperacc" element={<LinkedHelperAcc />} />
            <Route path="/buyplan" element={<Buyplan />} />
            <Route path="/specifications" element={<Specifications />} />
            {/* <Route path="/Adminpanel" element={<Adminpanel />} /> */}
            <Route path="/execmngt" element={<Execmngt />} />
            <Route path="/AccountsLedger" element={<AccountsLedger />} />
            <Route path="/privacypolicy" element={<Privacypolicy/>}/>
          </Routes>
          <Footer />
          <ToastContainer />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </>
  );
}
export default App;
