import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { RiShutDownLine } from "react-icons/ri";
import { Button } from '@mui/material';
import { doLogout } from '../auth';
import './Adminsidepanel.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

function Adminsidepanel() {
  const location = useLocation();
  const [logoutWarningOpen, setLogoutWarningOpen] = useState(false);

  const handleLogout = () => {
    setLogoutWarningOpen(true);
  };

  const handleLogoutConfirmed = () => {
    doLogout(() => {
      window.location.href = '/';
    });
  };

  const handleLogoutCancel = () => {
    setLogoutWarningOpen(false);
  };

  return (
    <div className="adminpanelsidebar">
      <div className="page-links">
        <div className={`dataInputLink ${location.pathname === '/datainputpage' ? 'active' : ''}`}>
          <Link to="/datainputpage">Campaign Data</Link>
        </div> 
        <div className={`customerManagementLink ${location.pathname === '/campaign' ? 'active' : ''}`}>
          <Link to="/campaign">Customer Data</Link>
        </div>
        <div className={`accountsLedgerLink ${location.pathname === '/createplan' ? 'active' : ''}`}>
          <Link to="/createplan">Plan Management</Link>
        </div>
        <div className={`accountsLedgerLink ${location.pathname === '/AccountsLedger' ? 'active' : ''}`}>
          <Link to="/AccountsLedger">Accounts Ledger</Link>
        </div>
        <div className="logout-option-sidebar" onClick={handleLogout}>
        <div className='logout-icon'><RiShutDownLine/></div> <Link>Logout</Link>
        </div>
      </div>

      <Snackbar
        open={logoutWarningOpen}
        autoHideDuration={10000}
        onClose={handleLogoutCancel}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className='logout-popup'
       
      >
        <Alert onClose={handleLogoutCancel} severity="warning">
          Are you sure you want to log out?
          <Button
            variant="outlined"
            color="warning"
            size="medium"
            onClick={handleLogoutConfirmed}
          >
            Yes, Logout
          </Button>
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Adminsidepanel;
