// plan-service.js
import { getToken } from "../auth";
import { privateAxios } from "./helper";

export const AddGraphData = async (data) => {
  const token = getToken();

  try {
    if (token) {
      const response = await privateAxios.post('/api/graphdata', data, { timeout: 10000 });
      console.log('Request Data:', data);
      console.log('Server Response:', response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error('Error in AddGraphData:', error);
    throw error;
  }
};

export const GetGraphData = async () => {
  const token = getToken();

  try {
    if (token) {
      const response = await privateAxios.get('/api/graphdata', { timeout: 10000 });
      console.log(response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const UpdateGraphData = async (id, updatedData) => {
  const token = getToken();

  try {
    if (token) {
      const response = await privateAxios.put(`/api/graphdata/${id}`, updatedData, { timeout: 10000 });
      console.log(response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const GetDailyActivityData = async (startDate, endDate) => {
  const token = getToken();

  try {
    if (token) {
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const fromDateParam = startDate ? `fromDate=${formatDate(startDate)}` : '';
      const toDateParam = endDate ? `toDate=${formatDate(endDate)}` : '';
      const apiUrl = `/api/graphdata/user/daily-activity?${fromDateParam}&${toDateParam}`;

      const response = await privateAxios.get(apiUrl, { timeout: 10000 });
      
      if (response.status === 204 || response.status === 403) {
        return { status: response.status, message: response.status === 204 ? 'We are starting to work on your campaign data.' : 'You do not have an active plan. Please buy/renew a plan.' };
      }
      console.log(response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const GetInviteAndAcceptData = async (startDate, endDate) => {
  const token = getToken();

  try {
    if (token) {
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const fromDateParam = startDate ? `fromDate=${formatDate(startDate)}` : '';
      const toDateParam = endDate ? `toDate=${formatDate(endDate)}` : '';
      const apiUrl = `/api/graphdata/user/invite-and-accept?${fromDateParam}&${toDateParam}`;

      const response = await privateAxios.get(apiUrl, { timeout: 10000 });
      if (response.status === 204 || response.status === 403) {
        return { status: response.status, message: response.status === 204 ? 'We are working on your campaign data.Please check again later.' : 'You do not have an active plan. Please buy/renew a plan.' };
      }

      console.log(response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch(error) {
    console.error(error);
    throw error;
  }
};

export const GetMessageReplyData = async (startDate, endDate) => {
  const token = getToken();

  try {
    if (token) {
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const fromDateParam = startDate ? `fromDate=${formatDate(startDate)}` : '';
      const toDateParam = endDate ? `toDate=${formatDate(endDate)}` : '';
      const apiUrl = `/api/graphdata/user/message-reply?${fromDateParam}&${toDateParam}`;

      const response = await privateAxios.get(apiUrl, { timeout: 10000 });
     
      if (response.status === 204 || response.status === 403) {
        return { status: response.status, message: response.status === 204 ? 'We are starting to work on your campaign data.' : 'You do not have an active plan. Please buy/renew a plan.' };
      }

      console.log(response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addProjectSpecification = async (data) => {
  const token = getToken();

  try {
      if (token) {
          const response = await privateAxios.post('/api/projectspecification', data, { timeout: 10000 });
          console.log(response);
          return response.data;
      } else {
          console.error('User not logged in.');
          throw new Error('User not logged in.');
      }
  } catch (error) {
      console.error(error);
      throw error;
  }
};

export const GetProjectSpecification = async () => {
  const token = getToken();

  try {
    if (token) {
      const response = await privateAxios.get('/api/user/projectspecification-list', { timeout: 10000 });
      console.log(response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const GetAllUsers = async () => {
  const token = getToken();

  try {
    if (token) {
      const response = await privateAxios.get('/client/admin-get-all-users', { headers: { Authorization: `Bearer ${token}` }, timeout: 10000 });
      console.log(response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const GetAllProjectSpecifications = async () => {
  try {
    const token = getToken();

    if (token) {
      const apiUrl = '/client/getall/projectSpecification';
      const response = await privateAxios.get(apiUrl, { 
        headers: { Authorization: `Bearer ${token}` },
        timeout: 10000 
      });
      
      console.log(response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error("Error retrieving all project specifications:", error);
    throw error;
  }
};
export const createPlan = async (planData) => {
  const token = getToken();

  try {
    if (token) {
      const response = await privateAxios.post('/api/plans/admin/create-plans', planData, { headers: { Authorization: `Bearer ${token}` }, timeout: 10000 });
      console.log(response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error("Error creating plan:", error);
    throw error;
  }
};
export const getRecentPlans = async () => {
  const token = getToken();

  try {
    if (token) {
      const response = await privateAxios.get('/api/plans/admin/plans', { headers: { Authorization: `Bearer ${token}` }, timeout: 10000 });
      console.log(response);
      return response.data.plansList;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error("Error fetching recent plans:", error);
    throw error;
  }
};

export const BuyPlan = async (planId) => {
  const token = getToken();

  try {
    if (token) {
      const response = await privateAxios.post(`/api/plans/user/buy/plan?planid=${planId}`, null, { timeout: 10000 });
      console.log(response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const CheckTransaction = async (transactionId) => {
  const token = getToken();

  try {
    if (token) {
      const response = await privateAxios.post(`/api/plans/user/create/transaction`, transactionId );
      console.log(response);
      return response;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getUserPlans = async (duration) => {
  const token = getToken();

  try {
    if (token) {
      const response = await privateAxios.get(`/api/plans/get/user/plans?month=${duration}`);
      console.log('getUserPlans response:', response.data);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error('Error fetching user plans:', error);

    if (error.response) {
      console.error('Response status:', error.response.status);
      console.error('Response data:', error.response.data);
    }

    throw error;
  }
};

export const AddProjectSpecification = async (data) => {
  const token = getToken();
  try {
    if (token) {
      const response = await privateAxios.post(
        '/api/project-specification/user/plans',
        data,
        { timeout: 10000 }
      );
      console.log('Request Data:', data);
      console.log('Server Response:', response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error('Error in AddProjectSpecification:', error);
    throw error;
  }
};

export const GetTransactionsData = async () => {
  const token = getToken();

  try {
    if (token) {
      const response = await privateAxios.get('/api/plans/admin/transactions', { headers: { Authorization: `Bearer ${token}` } });
      console.log(response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error('Error in GetTransactionsData:', error);
    throw error;
  }
};

export const updateUserProfile = async (userData) => {
  const token = getToken();

  try {
    if (token) {
      const response = await privateAxios.put('/api/user/update-user', userData, { timeout: 10000 });
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error('Error in UpdateUserProfile:', error);
    throw error;
  }
};
export const DeleteGraphData = async (id) => {
  const token = getToken();

  try {
    if (token) {
      const response = await privateAxios.delete(`/api/graphdata/${id}`, { timeout: 10000 });
      console.log('Server Response:', response);
      return response.data;
    } else {
      console.error('User not logged in.');
      throw new Error('User not logged in.');
    }
  } catch (error) {
    console.error('Error in DeleteGraphData:', error);
    throw error;
  }
};
export const updateExcelLink = async (data) => {
  try {
    const response = await privateAxios.put(`/api/project-specification/update`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating Excel link:", error);
    throw error;
  }
};
// Add this new function to handle updating the project specification with the Excel link
export async function AddExcelLink(projectId, activeStatus, excelLink) {
  try {
    const response = await fetch(`/api/project-specification/update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        projectId: projectId,
        activeStatus: activeStatus,
        excelLink: excelLink,
      }),
    });
    return await response.json();
  } catch (error) {
    console.error('Error updating project specification:', error);
    throw error;
  }
}
