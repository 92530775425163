import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { NavLink, useLocation } from 'react-router-dom';
import './styles.css';

const Footer = () => {
  const location = useLocation();
  const footer = location.pathname !== '/login'
    && location.pathname !== '/dashboard'
    && location.pathname !== '/datainputpage'
    && location.pathname !== '/LinkedHelperAcc'
    && location.pathname !== '/Adminpanel' && location.pathname !== '/execmngt'
    && location.pathname !== '/AccountsLedger' && location.pathname !== '/buyplan'
    && location.pathname !== '/specifications' && location.pathname !== '/campaign'
    && location.pathname !== '/createplan' && location.pathname !== '/createplan'
    && location.pathname !== '/privacypolicy' && location.pathname !== '/privacypolicy';

  return (
    footer && (

      <div className="footer-container">

        <div className="footer-card1">
          <h3>Technical Support </h3>
          <a href="mailto:support@connectingprospects.com">support@connectingprospects.com</a>
          <div className="social-icons">
         
            <a href="https://www.linkedin.com/in/nagesh-gavhane-111154/" rel="noopener noreferrer">
            Follow us : <i className="fab fa-linkedin"/>
              
            </a>
          </div>
          
        </div>

        <div className="footer-card2">
          <h3>Quick Links</h3>
          <a><NavLink to="/">Home</NavLink></a>
          <a><NavLink to="/About">About</NavLink></a>
          <a><NavLink to="/Solutions">Solutions</NavLink></a>
          <a><NavLink to="/Pricing">Pricing</NavLink></a>
          <a><NavLink to="/contactus">Contact Us</NavLink></a>
          <a>
            <NavLink to="/privacypolicy">Privacy Policy</NavLink>
          </a>
        </div>
        <div className="footer-card3">
          <h3>Address</h3>
          <p>
            <b>Connecting Prospects</b><br />
            1st floor, Gokhale Sanchit, BMCC Rd,
            Near Marathwada College,<br />
            Shivajinagar, Pune, Maharashtra 411004
          </p>

        </div>
        <div className="map-container">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d73568.41173878397!2d73.80301217304266!3d18.515817402448363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf86878bdb69%3A0x5d4ce39fe51677df!2sRikaian%20Technology%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1706521456917!5m2!1sen!2sin"
            width="340" height="280" style={{ border: "0" }}
            allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade" />
        </div>
      </div>
    )
  );
};
export default Footer;
