import React, { useState, useEffect } from "react";
import Navbar from "../Sidebar/Navbar";
import AdminSidepanel from "../Sidebar/AdminSidepanel";
import { TextField, Checkbox, Button, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { createPlan, getRecentPlans } from "../services/plan-service";
import './createplans.css';

const PlanCard = ({ plan }) => (
    <TableRow>
        <TableCell>{plan.plan_name}</TableCell>
        <TableCell>{plan.price}</TableCell>
        <TableCell>{plan.according_month}</TableCell>
        <TableCell>{plan.discount}%</TableCell>
    </TableRow>
);

function Createplans() {
    const [planData, setPlanData] = useState({
        plan_name: "",
        price: 0,
        discount: 0,
        according_month: 0,
        lead_generation: false,
        invite_connection: 0,
        prospect_identify:0,
        message_followups: false,
        reply_detection: false,
        linkedin_page_follow_request: false,
        cold_mail_campaign: 0,
    });

    const [recentPlans, setRecentPlans] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        fetchRecentPlans();
    }, []);

    const fetchRecentPlans = async () => {
        try {
            const plans = await getRecentPlans();
            setRecentPlans(plans);
        } catch (error) {
            console.error("Error fetching recent plans:", error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setPlanData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await createPlan(planData);
            console.log("Plan created successfully!");
            setPlanData({
                plan_name: "",
                price: 0,
                discount: 0,
                according_month: 0,
                lead_generation: false,
                invite_connection: 0,
                prospect_identify:0,
                message_followups: false,
                reply_detection: false,
                linkedin_page_follow_request: false,
                cold_mail_campaign: false,
            });
            fetchRecentPlans();
        } catch (error) {
            console.error("Error creating plan:", error);
        }
    };

    const handleCancel = () => {
        setPlanData({
            plan_name: "",
            price: 0,
            discount: 0,
            according_month: 0,
            lead_generation: false,
            invite_connection: 0,
            prospect_identify:0,
            message_followups: false,
            reply_detection: false,
            linkedin_page_follow_request: false,
            cold_mail_campaign: false,
        });
    };

    return (
        <div>
            <Navbar />
            <AdminSidepanel />
            <div className="craeteplan">
               <div className="create-plan-title">
               <h2>Create Plan</h2>
                </div> 
                <form onSubmit={handleSubmit}>
                    <div>
                        <TextField
                            label="Plan Name"
                            type="text"
                            name="plan_name"
                            value={planData.plan_name}
                            onChange={handleChange}
                            required

                        />
                        <TextField
                            label="Price"
                            type="number"
                            name="price"
                            value={planData.price}
                            onChange={handleChange}
                            required
                            style={{ marginLeft: '13px' }}
                        />
                        <TextField
                            label="Discount"
                            type="number"
                            name="discount"
                            value={planData.discount}
                            onChange={handleChange}
                            required
                            style={{ marginLeft: '13px' }}
                        />
                        <TextField
                            label="According Month"
                            type="number"
                            name="according_month"
                            value={planData.according_month}
                            onChange={handleChange}
                            required
                            style={{ marginLeft: '13px' }}
                        />
                    </div>
                    <div className="create-plan-title">
                        <h2>Plan Includes</h2>
                    </div>
                    <div>
                        <TextField
                            label="Invite Connection"
                            type="number"
                            name="invite_connection"
                            value={planData.invite_connection}
                            onChange={handleChange}
                            required
                            style={{ marginLeft: '10px' }}
                        />
                        <TextField
                            label="Number of Prospect Identify"
                            type="number"
                            name="prospect_identify"
                            value={planData.prospect_identify}
                            onChange={handleChange}
                            required
                            style={{ marginLeft: '10px' }}
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            control={<Checkbox checked={planData.lead_generation} onChange={handleChange} name="lead_generation" />}
                            label="Lead Generation" style={{ marginLeft: '1px', marginTop: '15px' }}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={planData.linkedin_page_follow_request} onChange={handleChange} name="linkedin_page_follow_request" />}
                            label="LinkedIn Page Follow Request" style={{ marginLeft: '55px', marginTop: '15px' }}
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            control={<Checkbox checked={planData.reply_detection} onChange={handleChange} name="reply_detection" />}
                            label="Reply Detection" style={{ marginLeft: '1px', marginTop: '10px' }}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={planData.message_followups} onChange={handleChange} name="message_followups" />}
                            label="Message Follow Ups" style={{ marginLeft: '59px', marginTop: '10px' }}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={planData.cold_mail_campaign} onChange={handleChange} name="cold_mail_campaign" />}
                            label="Cold Email Campaign" style={{ marginLeft: '59px', marginTop: '10px' }}
                        />
                    </div>
                    <Button className="createplan-btn" type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }} >
                        Create Plan
                    </Button>
                    <Button className="clear--btn"   type="button" variant="contained"  style={{ marginLeft: '20px', marginTop: '20px' }} onClick={handleCancel}>
                        Cancel
                    </Button>

                </form>

                <div>
                    <h2>Recent Plans</h2>
                    <TableContainer component={Paper} style={{ maxHeight: 300 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Plan Name</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Months</TableCell>
                                    <TableCell>Discount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {recentPlans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((plan) => (
                                    <PlanCard key={plan.plan_id} plan={plan} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={recentPlans.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </div>
    );
}
export default Createplans;
