import React from 'react';
import './navbar.css';
import logo from '../image/new_logo.png';

const Navbar = () => {
  return (
   

<div className="logout-option-navbar">
  <img src={logo} alt="ConnectIn Logo" className="header__logo" />
</div>
  );
};

export default Navbar;
