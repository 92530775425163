import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import Header from "./header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './home.css';
import image2 from '../image/data_solution2.jpeg';
import image3 from '../image/data_solution.jpg';
import image4 from '../image/connectinimg1-transformed.png';
import landingPageImage from '../image/landing-page1.jpg';  // import the landing page image
import { Button } from '@mui/material';

function Home() { 
    const navigate = useNavigate(); 
    const redirectToSolutionPage = () => {
        navigate('/solutions'); 
    };

    return (
        <div>
            <Header />
            <div className="hero-section">
              <div className="homepage" style={{ backgroundImage: `url(${landingPageImage})` }}>
               
                <div  className="hero-overlay">
                    <h2>Boost Your Lead Generation With Us</h2>
                    <h4>Helping Companies Find Customers</h4>
                    {/* <button className="know-more-btn" onClick={redirectToSolutionPage}>KNOW MORE</button> */}
                </div>
                </div>
            </div>
            <div className="content-container">
                <div className="content-section">
                    <div className="left-box1">
                        <h3>Tailored Lead Generation Solutions for Your Sales and Marketing Goals</h3>
                        <img src={image2} alt="Hero" className="home-img" />
                        <div className="box-content">               
                            <p>We've developed a comprehensive suite of lead generation services designed to align perfectly with your sales and marketing objectives. Our services are meticulously crafted and seamlessly integrated to ensure maximum effectiveness in generating high-quality leads for your business.</p>
                        </div>
                    </div>
                    <div className="left-box2">
                        <h3>Customized Lead Generation Solutions for Targeted Success</h3>
                        <img src={image3} alt="Hero" className="home-img" />
                        <div className="box-content">     
                            <p>With a focus on customization and precision, we tailor our lead generation strategies to suit your specific needs, whether you're targeting a niche market or seeking broader outreach. Our team works closely with you to understand your unique goals and challenges, delivering solutions that drive tangible results and boost your bottom line.</p>
                        </div>
                    </div>
                    <div className="left-box3">
                        <h3>Unlock Your Business Potential with Tailored Lead Generation</h3>
                        <img src={image4} alt="Hero" className="home-img" />
                        <div className="box-content">            
                            <p>Explore our range of lead generation services today and experience the difference tailored solutions can make for your business success.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );      
}
export default Home;
