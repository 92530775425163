import React, { useState } from "react";
import Header from "./header";
import './contactus.css';
import { sendContactDetails } from "../services/user-service";

function Contactus() {
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        contact: '',
        message: ''
    });

    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await sendContactDetails(formData);
            setSubmissionStatus('success');
            console.log('Contact details submitted successfully:', response);
            
            // Reset form fields
            setFormData({
                name: '',
                company: '',
                email: '',
                contact: '',
                message: ''
            });
        } catch (error) {
            setSubmissionStatus('error');
            console.error('Error submitting contact details:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Header />
            <div className="contactus">
                <h3>Looking for lead generation solutions?</h3>
                <p>Fill out this form and our specialists will get in touch.</p>
                <div className="contact-content">
                    <div className="contact-form">
                        <form onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="name"></label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="company"></label>
                                    <input
                                        type="text"
                                        id="company"
                                        name="company"
                                        placeholder="Company Name"
                                        value={formData.company}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="email"></label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="contact"></label>
                                    <input
                                        type="tel"
                                        id="contact"
                                        name="contact"
                                        placeholder="Phone"
                                        value={formData.contact}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="message"></label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows="4"
                                        placeholder="Message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    ></textarea>
                                </div>
                            </div>
                            <button type="submit" className="contform-btn" disabled={isLoading}>
                                {isLoading ? 'Submitting...' : 'Submit'}
                            </button>
                        </form>
                        {submissionStatus === 'success' && <p>Form submitted successfully!</p>}
                        {submissionStatus === 'error' && <p>Failed to submit the form. Please try again.</p>}
                    </div>
                    <div className="contact-address">
                        <h4>Our Office Address:</h4>
                        <p>Email: support@connectingprospects.com</p>
                        <p>Contact Number: (+91) 82753 87644</p>
                        <p>1st floor, Gokhale Sanchit, BMCC Rd,<br />
                           Near Marathwada College,<br />
                           Shivajinagar, Pune, Maharashtra 411004</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contactus;
